import { graphql } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import PostListing from '../components/PostListing';
import Layout from '../components/Layout';
import PropTypes from '../PropTypes';
import config from '../../data/config';

const propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    category: PropTypes.any.isRequired
  }).isRequired
};

function CategoryPage({ location, data, pageContext }) {
  const { category } = pageContext;
  const postEdges = data.posts.edges;

  return (
    <Layout location={location}>
      <Helmet title={config.categoriesHash[category]} />
      <PostListing postEdges={postEdges} />
    </Layout>
  );
}

CategoryPage.propTypes = propTypes;

export default CategoryPage;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: String) {
    posts: allPrismicPost(
      limit: 1000
      sort: { fields: [data___date], order: DESC }
      filter: { data: { category: { uid: { eq: $category } } } }
    ) {
      edges {
        node {
          slug: uid
          frontmatter: data {
            title {
              text
            }
            description
            date
            cover {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 800, quality: 90, toFormat: JPG) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
